





















import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {VersionTaskState} from '@/tasks/types/VersionTaskState';

export default defineComponent({
  name: 'UofTQuiz1Question3',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  props: {
    taskState: {
      type: Object as PropType<VersionTaskState>,
      required: true,
    },
  },
  data() {
    return {
      LSattachments: [] as File[],
    };
  },
  computed: {
    image(): string {
      return `/img/assignments/UofT/CHMB41H/2023/Quiz1/Question3/v${
        this.taskState.getValueByName('version')?.content?.value ?? 1
      }.png`;
    },
    attachments(): File[] {
      return [...this.LSattachments];
    },
  },
});
